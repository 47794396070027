let docReady = function(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
      // call on next available tick
      setTimeout(fn, 1);
  } else {
      document.addEventListener("DOMContentLoaded", fn);
  }
}

let filterProjects = function($gutter) {

  let filterButtons = document.querySelector('.tags.-intro');

  if (typeof (filterButtons) != 'undefined' && filterButtons != null) {
    let elem = document.querySelector('.projects');
    let filterButton = filterButtons.querySelectorAll('[href="' + window.location.hash + '"]');

    if (typeof (elem) != 'undefined' && elem != null) {
      var iso = new Isotope(elem, {
        // options
        itemSelector: '.projects__item',
        percentPosition: true,
        layoutMode: 'packery',
        packery: {
          gutter: '.projects__gutter'
        }
      });
      imagesLoaded( elem, function( instance ) {
        iso.arrange({
          transitionDuration: 0
        });
        elem.classList.add('-fadeIn')
      });

      if(window.location.hash && filterButton.length) {
        const introHeadline = document.getElementsByClassName('intro__headline')[0];
        introHeadline.innerHTML = filterButton[0].innerHTML;

        iso.arrange({
          transitionDuration: 0,
          filter: function (itemElem) {
            let tagsArray = itemElem.dataset.tags.split('|');
            return (tagsArray.includes(decodeURI(window.location.hash.substring(1))));
          }
        });
      }
    }


    // bind filter button click
    filterButtons.addEventListener('click', function (event) {

      // do nothing if filter is already set
      if (event.target.classList.contains('-active')) {
        return;
      }

      function typeIt( string, target ) {

        target.style.minHeight = target.clientHeight + 'px';

        var hold = [];
        var step = 1;
        var text = target.textContent.trim();

        var backspace = setInterval(function() {
          if(text.length == 0) {
            clearInterval(backspace);
            var tick = setInterval(function() {
              if(step == string.length) {
                clearInterval(tick)
              };
              hold.push(string.slice(step - 1, step));
              step += 1;
              target.innerHTML = hold.join('');
            }, 20);
          };
          text =  text.substr(0, text.length - 1);
          target.textContent = text;
        }, 20);
    }
    const stringMessage = event.target.textContent.trim();
    let introHeadline = document.getElementsByClassName('intro__headline')[0];
    typeIt( stringMessage, introHeadline );

      // set active state
      filterButtons.querySelectorAll('.tags__button').forEach(function (element) {
        element.classList.remove("-active");
      });
      event.target.classList.add('-active');

      // filtering
      let filterValue = event.target.attributes.href.value.split('#')[1];
      iso.arrange({
        transitionDuration: 500,
        filter: function (itemElem) {
          let tagsArray = itemElem.dataset.tags.split('|');
          return (tagsArray.includes(filterValue));
        }
      });
    });
  }
}

let projectsTooltip = function () {
  // hover effect for project tiles
  var projectsItem = document.querySelectorAll('.projects__item');
  if (typeof (projectsItem) != 'undefined' && projectsItem != null) {
    for (var i = 0; i < projectsItem.length; i++) {
      tippy(
        projectsItem[i], {
          followCursor: true,
          allowHTML: true,
          popperOptions: {
            modifiers: [
              {
                name: 'preventOverflow',
                options: {
                  boundary: projectsItem[i],
                  altAxis: true,
                  tether: false,
                  padding: {
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 60
                  },
                },
              },
            ],
          },
        }
      );
    }
  }
}

let popupImages = function () {
  //
  // ~~~IMAGE POPUPS ~~~
  //
  const imageLinks = document.querySelectorAll('.-popup');
  if (typeof (imageLinks) != 'undefined' && imageLinks != null) {
    for (let i = 0; i < imageLinks.length; i++) {
      imageLinks[i].addEventListener('mouseenter', function (e) {
        this.classList.add('-hover')
      })
      imageLinks[i].addEventListener('mouseleave', function (e) {
        this.classList.remove('-hover')
      })
      imageLinks[i].addEventListener('click', function (e) {
        e.preventDefault()
        const elem = this
        elem.classList.add('-focus')
        BigPicture({
          el: e.target,
          imgSrc: elem.href,
          onClose: function() {
            elem.classList.remove('-focus')
          }
        })
      })
    }
  }
}


docReady(function() {

  console.log('[main] Document ready.')

  // projects: isotope
  filterProjects()

  // Lightbox
  popupImages()

  enquire.register("screen and (max-width: 767px)", {

    deferSetup: true,

    setup: function () {
      console.log('[enquire][mobile] setup');
    },

    match: function () {
      console.log('[enquire][mobile] match');
    },

    unmatch: function () {
      console.log('[enquire][mobile] unmatch');
    }

  });

  enquire.register("screen and (min-width: 768px)", {

    deferSetup: true,

    setup: function () {
      console.log('[enquire][desktop] setup');
    },

    match: function () {
      console.log('[enquire][desktop] match');
      projectsTooltip();
    },

    unmatch: function () {
      console.log('[enquire][desktop] unmatch');
    }

  });


  // homepage: load next page on click/scroll
  let splash = document.querySelector('.-splash');
  if (typeof (splash) != 'undefined' && splash != null) {
    document.addEventListener('wheel', loadNextPage);
    document.addEventListener('click', loadNextPage);
    document.addEventListener('touchmove', loadNextPage);

    let gotEvent = false;
    function loadNextPage() {
      if(!gotEvent) {
        splash.classList.add('-fadeOut');
        window.location.href = '/' + splash.dataset.nextpage;
        gotEvent = true;
      }
    }
  }

  // hamburger
  const hamburger = document.getElementById('hamburger');
  if (typeof (hamburger) != 'undefined' && hamburger != null) {
    hamburger.addEventListener('click', function (e) {
      document.getElementsByTagName('body')[0].classList.toggle('-open-navigation');
    });
  }


  // searchbox
  let searchbox = document.querySelector('.searchbox__image');
  if (typeof (searchbox) != 'undefined' && searchbox != null) {
    searchbox.addEventListener('click', function(e) {
      const className = '-open-searchbox';
      const parent = e.currentTarget.parentNode;

      e.preventDefault();

      if(parent.classList.contains(className)) {
        parent.classList.remove(className);
        document.querySelector('.searchbox__input').blur();
      } else {
        parent.classList.add(className);
        document.querySelector('.searchbox__input').focus();
      }
    });
  }

});
